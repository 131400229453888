import "./Classes.css";
import React from "react";
import { Box, Paragraph, Heading } from "grommet";
import CarouselBox from "../components/CarouselBox.js";

export default function Classes(props) {
  return (
    <Box>
      <CarouselBox
        image={"dancing-rj.jpg"}
        heading={"Classes"}
        text={"Flamenco dance classes"}
      />
      <p>
        Infromation on current classes can be found on our{" "}
        <a href="https://sites.google.com/view/flamencotulsaenrollment?usp=sharing">
          class enrollment site
        </a>
      </p>
    </Box>
  );
}
