import "./MainMenu.css";
import React, { useState } from "react";
import { Menu as MenuIcon, Shop, Logout, Article, StatusGood, Validate, Calendar } from 'grommet-icons';
import { Box, DropButton, Anchor } from 'grommet';

export default function MainMenu(props) {

  const { size, handleLogout, state, history, menuOverride } = props;

  const menuList = (props) => {
    let menuItems = [
      {
        label: "Student portal",
        icon: Validate,
        href: "https://portal.flamencotulsa.com",
        title: "Student portal",
      },
      {
        label: "Classes",
        icon: StatusGood,
        href: 'https://sites.google.com/view/flamencotulsaenrollment',
        title: "Classes",
      },
      {
        label: "Blog",
        icon: Article,
        to: "/blog",
        onClick: () => {
          history.push("/blog");
          setMenuOpen(false);
        },
        title: "Blog",
      },
      {
        label: "Calendar",
        icon: Calendar,
        to: "/calendar",
        onClick: () => {
          history.push("/calendar");
          setMenuOpen(false);
        },
        title: "Calendar",
      },
      // { label: 'Login', icon: Login, onClick: () => {history.push('/login'); setMenuOpen(false)}, disabled: state.isAuthenticated, to: '/login', title: 'Login' },
      {
        label: "Logout",
        icon: Logout,
        onClick: () => {
          handleLogout();
          setMenuOpen(false);
        },
        disabled: !state.isAuthenticated,
        to: "/logout",
        title: "Logout",
      },
    ];

    if (menuOverride) {
      menuItems = menuOverride;
    }

    let menu = [];
    menuItems.map((item) => {
      if (!item.disabled) {
        menu.push(item);
      }
      return item
    })

    return menu.map((item) => {
      const Icon = item.icon
      if (item.href) {
        return (
          <Anchor
            key={item.title}
            label={item.label}
            className="mobileLink"
            href={item.href}
            a11yTitle={item.title}
            tooltip={item.title}
            icon={<Icon />}
          />
      )
      } else {
        return (
            <Anchor
              key={item.title}
              onClick={item.onClick}
              label={item.label}
              className="mobileLink"
              to={item.to}
              a11yTitle={item.title}
              tooltip={item.title}
              icon={<Icon />}
            />
        )
      }
      });
  }

  const [menuOpen, setMenuOpen] = useState(false);

  return (
      (size === 'small') ? <DropButton
        label=""
        icon={<MenuIcon />}
        onOpen={() => {setMenuOpen(true)}}
        onClose={() => {setMenuOpen(false)}}
        open={menuOpen}
        className={'hamburgerMenu'}
        dropAlign={{ top: 'bottom', right: 'right' }}
        dropContent={
          <Box pad="medium" background="light-2">
              { menuList(props) }
          </Box>
        }
      /> : <Box className="mainMenu">
        <ul className="mainMenu">
          { menuList(props) }
        </ul>
      </Box>
  )
}
