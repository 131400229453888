import "./Calendar.css";
import React from "react";
import { Box, Heading, ResponsiveContext, Text, Paragraph } from "grommet";
import CarouselBox from "../components/CarouselBox.js";

export default function Calendar(props) {
  return (
    <div className="Calendar">
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box>
            <Box>
              <CarouselBox
                image={"feriapic.jpeg"}
                heading={"Calendar"}
                text={"Class schedules and upcoming events."}
              />
            </Box>
            <Box border="top" margin={{ top: "large", bottom: "large" }}>
              <iframe
                src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FChicago&src=djB2dDRmNzhrb2k5bGFib2hla3U1Zm51M2NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23D81B60"
                style={{borderWidth: 0, margin: "auto", width: "100%"}}
                width="800"
                height="600"
                frameborder="0"
                scrolling="no"
              ></iframe>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </div>
  );
}
